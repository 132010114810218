import { DateTime } from 'luxon';

let Helpers = function () {};

Helpers.prototype = {
    init() {
        this.setStartTime();
    },

    setStartTime() {
        let now = DateTime.local().setZone('Europe/Berlin');
        let munich = DateTime.local(2013, 6, 13).setZone('Europe/Berlin');

        // reset to start of day
        now = now.startOf('day');

        if (now.isInDST !== munich.isInDST) {
            // subtract one hour if now is not in dst
            now = now.minus({ hours: 1 });
        }

        this.pointZero = now.ts;
    },

    timestrToSec(val) {
        return (
            parseInt(val.substr(0, 2)) * 60 * 60 +
            parseInt(val.substr(2, 2)) * 60 +
            parseInt(val.substr(4, 2))
        );
    },

    getCorrectedNow() {
        let time = DateTime.local().ts;
        return time - this.pointZero;
    },
};

module.exports = Helpers;
