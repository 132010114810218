let CrossfadePlaylist = function (ambientsound) {
    this.ambientsound = ambientsound;
    this.context = ambientsound.context;
};

CrossfadePlaylist.prototype = {
    FADE_TIME: 1, // Seconds
    playing: false,
    currentList: null,
    currentTotal: null,
    currentLoaded: null,

    play(sound) {
        let self = this;

        sound = sound || 0;
        this.playing = true;

        this.playHelper(self.currentList.buffers[sound], sound);
    },

    playNext(ignore) {
        this.ambientsound.setCurrent();

        if (
            this.ambientsound.tramclock.isMuted ||
            (this.ambientsound.tramclock.tramvideo &&
                this.ambientsound.tramclock.tramvideo.isPlaying) ||
            this.currentList === null
        )
            return false;

        ignore =
            ignore === undefined || ignore === null
                ? Math.floor(Math.random() * this.currentList.buffers.length)
                : ignore;

        if (this.currentList.buffers.length === 1) {
            this.play(0);
        } else if (this.currentList.buffers.length > 1) {
            let nextsound = ignore;
            while (nextsound === ignore) {
                nextsound = Math.floor(
                    Math.random() * this.currentList.buffers.length,
                );
            }
            this.play(nextsound);
        }
    },

    stop() {
        let self = this;

        clearTimeout(self.timer);

        if (!self.playing) return;

        let currTime = self.context.currentTime;
        self.gainNode.gain.linearRampToValueAtTime(
            0,
            currTime + self.FADE_TIME,
        );

        let temp_source = self.source;

        setTimeout(function () {
            if (!temp_source.stop) temp_source.stop = temp_source.noteOff;
            temp_source.stop(0);
        }, self.FADE_TIME * 1000);

        self.playing = false;
    },

    toggle() {
        if (this.playing) {
            this.stop();
        } else {
            this.playNext();
        }
    },

    playHelper(bufferNow, ignore) {
        let self = this;

        let playNow = self.createSource(bufferNow);
        let source = playNow.source;
        self.source = source;
        let gainNode = playNow.gainNode;
        self.gainNode = gainNode;
        let duration = bufferNow.duration;
        let currTime = self.context.currentTime;
        // Fade the playNow track in.
        gainNode.gain.linearRampToValueAtTime(0, currTime);
        gainNode.gain.linearRampToValueAtTime(0.3, currTime + self.FADE_TIME);
        // Play the playNow track
        source.start(0);
        // At the end of the track, fad,e it out.
        gainNode.gain.linearRampToValueAtTime(
            0.3,
            currTime + duration - self.FADE_TIME,
        );
        gainNode.gain.linearRampToValueAtTime(0, currTime + duration);

        // Schedule a recursive track change with the tracks swapped.
        // let recurse = arguments.callee;
        self.timer = setTimeout(function () {
            self.playNext(ignore);
            //recurse(bufferLater, bufferNow);
        }, duration * 1000);
    },

    createSource(buffer) {
        let self = this;

        let source = self.context.createBufferSource();
        let gainNode = self.context.createGain();
        source.buffer = buffer;
        // Connect source to gain.
        source.connect(gainNode);
        // Connect gain to destination.
        gainNode.connect(self.context.destination);

        return {
            source: source,
            gainNode: gainNode,
        };
    },
};

module.exports = CrossfadePlaylist;
