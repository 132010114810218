[
    {
        "start": "010000",
        "sounds": [
            "/media/audio/ambient/010000/002700.mp3",
            "/media/audio/ambient/010000/003000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "020000",
        "sounds": [
            "/media/audio/ambient/020000/001.mp3",
            "/media/audio/ambient/020000/002.mp3",
            "/media/audio/ambient/020000/004.mp3",
            "/media/audio/ambient/020000/075900.mp3"
        ],
        "buffers": []
    },
    {
        "start": "045000",
        "sounds": [
            "/media/audio/ambient/045000/001.mp3",
            "/media/audio/ambient/045000/002.mp3",
            "/media/audio/ambient/045000/003.mp3",
            "/media/audio/ambient/045000/004.mp3"
        ],
        "buffers": []
    },
    {
        "start": "070000",
        "sounds": [
            "/media/audio/ambient/070000/073000.mp3",
            "/media/audio/ambient/070000/074000.mp3",
            "/media/audio/ambient/070000/075900.mp3",
            "/media/audio/ambient/070000/080200.mp3",
            "/media/audio/ambient/070000/082000.mp3",
            "/media/audio/ambient/070000/084000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "085000",
        "sounds": [
            "/media/audio/ambient/085000/085000.mp3",
            "/media/audio/ambient/085000/085700.mp3",
            "/media/audio/ambient/085000/090700.mp3",
            "/media/audio/ambient/085000/091700.mp3"
        ],
        "buffers": []
    },
    {
        "start": "092500",
        "sounds": [
            "/media/audio/ambient/092500/092500.mp3",
            "/media/audio/ambient/092500/093700.mp3",
            "/media/audio/ambient/092500/095000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "100000",
        "sounds": [
            "/media/audio/ambient/100000/100000.mp3",
            "/media/audio/ambient/100000/104000.mp3",
            "/media/audio/ambient/100000/105000.mp3",
            "/media/audio/ambient/100000/111000.mp3",
            "/media/audio/ambient/100000/113000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "114500",
        "sounds": [
            "/media/audio/ambient/114500/114500.mp3",
            "/media/audio/ambient/114500/115700.mp3",
            "/media/audio/ambient/114500/120800.mp3",
            "/media/audio/ambient/114500/122000.mp3",
            "/media/audio/ambient/114500/122200.mp3",
            "/media/audio/ambient/114500/123000.mp3",
            "/media/audio/ambient/114500/124000.mp3",
            "/media/audio/ambient/114500/124800.mp3"
        ],
        "buffers": []
    },
    {
        "start": "125000",
        "sounds": [
            "/media/audio/ambient/125000/125000.mp3",
            "/media/audio/ambient/125000/125800.mp3",
            "/media/audio/ambient/125000/132400.mp3",
            "/media/audio/ambient/125000/133600.mp3"
        ],
        "buffers": []
    },
    {
        "start": "134000",
        "sounds": [
            "/media/audio/ambient/134000/134000.mp3",
            "/media/audio/ambient/134000/134800.mp3"
        ],
        "buffers": []
    },
    {
        "start": "140400",
        "sounds": [
            "/media/audio/ambient/140400/140400.mp3",
            "/media/audio/ambient/140400/141400.mp3",
            "/media/audio/ambient/140400/142800.mp3"
        ],
        "buffers": []
    },
    {
        "start": "144500",
        "sounds": [
            "/media/audio/ambient/144500/145500.mp3",
            "/media/audio/ambient/144500/153200.mp3",
            "/media/audio/ambient/144500/154000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "160000",
        "sounds": [
            "/media/audio/ambient/160000/160000.mp3",
            "/media/audio/ambient/160000/161300.mp3",
            "/media/audio/ambient/160000/161600.mp3",
            "/media/audio/ambient/160000/162800.mp3",
            "/media/audio/ambient/160000/163000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "170000",
        "sounds": [
            "/media/audio/ambient/170000/171300.mp3",
            "/media/audio/ambient/170000/174500.mp3",
            "/media/audio/ambient/170000/180700.mp3",
            "/media/audio/ambient/170000/181900.mp3",
            "/media/audio/ambient/170000/182800.mp3"
        ],
        "buffers": []
    },
    {
        "start": "190000",
        "sounds": [
            "/media/audio/ambient/190000/190700.mp3",
            "/media/audio/ambient/190000/192000.mp3",
            "/media/audio/ambient/190000/193800.mp3",
            "/media/audio/ambient/190000/195300.mp3"
        ],
        "buffers": []
    },
    {
        "start": "200000",
        "sounds": [
            "/media/audio/ambient/200000/201700.mp3",
            "/media/audio/ambient/200000/203000.mp3",
            "/media/audio/ambient/200000/203500.mp3",
            "/media/audio/ambient/200000/204000.mp3"
        ],
        "buffers": []
    },
    {
        "start": "210000",
        "sounds": [
            "/media/audio/ambient/210000/210700.mp3",
            "/media/audio/ambient/210000/212300.mp3",
            "/media/audio/ambient/210000/213500.mp3",
            "/media/audio/ambient/210000/214500.mp3",
            "/media/audio/ambient/210000/215100.mp3"
        ],
        "buffers": []
    },
    {
        "start": "220000",
        "sounds": [
            "/media/audio/ambient/220000/215800.mp3",
            "/media/audio/ambient/220000/220800.mp3",
            "/media/audio/ambient/220000/222300.mp3",
            "/media/audio/ambient/220000/222500.mp3",
            "/media/audio/ambient/220000/223200.mp3"
        ],
        "buffers": []
    },
    {
        "start": "230000",
        "sounds": [
            "/media/audio/ambient/230000/000300.mp3",
            "/media/audio/ambient/230000/230100.mp3",
            "/media/audio/ambient/230000/230700.mp3",
            "/media/audio/ambient/230000/231000.mp3",
            "/media/audio/ambient/230000/231500.mp3",
            "/media/audio/ambient/230000/232000.mp3",
            "/media/audio/ambient/230000/232600.mp3",
            "/media/audio/ambient/230000/234700.mp3"
        ],
        "buffers": []
    }
]
