let bufferloader = require('./bufferloader');
let Playlists = require('./file-lists/audio-ambient-files');

let AmbientSound = function (tramclock) {
    this.tramclock = tramclock;
    //
    // this.init();
    // if (this.context) this.play();
};

AmbientSound.prototype = {
    addedTime: 0,
    current: null,
    totalPlaylists: null,

    init() {
        this.totalPlaylists = Object.keys(Playlists).length;

        let AudioContext = this.tramclock.AudioContext;

        if (AudioContext) {
            this.context = AudioContext;

            let CrossFaderPlaylist = require('./crossfader');
            this.crossfader = new CrossFaderPlaylist(this);

            this.current = null;
            this.setCurrent();
        }
    },

    restart() {
        this.stop();
        this.current = null;
        this.setCurrent();
        this.play();
    },

    play() {
        this.crossfader.playNext();
    },

    stop() {
        this.crossfader.stop();
    },

    setCurrent() {
        let self = this;

        let latest = [-1, -self.tramclock.totalTime * 1000];
        let correctedNow = self.tramclock.helpers.getCorrectedNow();

        for (let i = this.totalPlaylists - 1; i >= 0; i--) {
            let distance =
                this.addedTime +
                self.tramclock.helpers.timestrToSec(Playlists[i].start) * 1000 -
                correctedNow;
            if (distance < 0 && distance > latest[1]) {
                latest = [i, distance];
            }
        }

        if (latest[0] < 0) {
            latest[0] = this.totalPlaylists - 1;
            if (this.current !== latest[0])
                this.addedTime =
                    this.addedTime - self.tramclock.totalTime * 1000;
        }

        if (
            this.current !== latest[0] &&
            this.current >= 0 &&
            this.current < this.totalPlaylists
        ) {
            this.current = latest[0];

            this.loadBuffers(this.current);

            // add time if the latest playlist
            if (latest[0] >= this.totalPlaylists - 1) {
                this.addedTime =
                    this.addedTime + self.tramclock.totalTime * 1000;
            }
        }
    },

    isPlaying() {
        return this.crossfader.playing;
    },

    loadBuffers(ambi) {
        let self = this;

        // Array-ify
        let names = [];
        let paths = [];
        for (let sound in Playlists[ambi].sounds) {
            let path = Playlists[ambi].sounds[sound];
            names.push(sound);
            paths.push(path);
        }

        let bufferLoader = new bufferloader(self.context, paths, function (
            bufferList,
        ) {
            for (let i = 0; i < bufferList.length; i++) {
                let buffer = bufferList[i];
                let name = names[i];
                Playlists[ambi].buffers[name] = buffer;
            }

            self.crossfader.currentList = Playlists[ambi];
            self.crossfader.currentTotal = Playlists[ambi].sounds.length;
            self.crossfader.currentLoaded = ambi;

            if (!self.crossfader.playing) {
                self.crossfader.playNext();
            }
        });

        bufferLoader.load();
    },
};

module.exports = AmbientSound;
