[
    "073714",
    "075958",
    "100000",
    "113100",
    "130908",
    "131045",
    "141200",
    "142955",
    "143227",
    "144141",
    "144333",
    "144452",
    "145535",
    "145725",
    "151540",
    "160000",
    "162330",
    "163039",
    "202930"
]
