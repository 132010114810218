import $ from 'jquery';

let Timeline = function (tramclock) {
    this.tramclock = tramclock;
    this.init();
};

Timeline.prototype = {
    width: 35000,
    prepend: false,
    count: 1,
    schedule: [
        [9, 29, 49],
        [12],
        [],
        [],
        [],
        [28, 42], // 5
        [0, 20, 40],
        [0, 20, 40],
        [0, 23, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51], // 10
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51], // 15
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 41, 51],
        [1, 11, 21, 31, 49], // 20
        [9, 29, 49],
        [9, 29, 49],
        [9, 29, 49],
    ],

    init() {
        let self = this;
        self.$el = $('#timeline');

        self.$el.width(self.width);

        self.loadDots();

        let munichNow = self.tramclock.helpers.getCorrectedNow();

        self.checkPrepend(munichNow / 1000);
        self.checkAppend(munichNow / 1000);

        setInterval(function () {
            let munichNow = self.tramclock.helpers.getCorrectedNow();
            self.checkAppend(munichNow / 1000);
        }, 10 * 1000);
    },

    startTimer(seconds, unstoppable) {
        // fallback if in background
        let self = this;
        let timer = setInterval(function () {
            let munichNow = self.tramclock.helpers.getCorrectedNow();
            self.moveTo(munichNow / 1000);
        }, seconds * 1000);

        if (!unstoppable) {
            self.checkTimer = timer;
        }
    },

    stopTimer() {
        let self = this;
        clearInterval(self.checkTimer);
    },

    loadDots() {
        let self = this;

        for (let i = this.schedule.length - 1; i >= 0; i--) {
            for (let j = this.schedule[i].length - 1; j >= 0; j--) {
                let tramSeconds = i * 60 * 60 + this.schedule[i][j] * 60;
                let posLeft =
                    self.$el.width() -
                    this.width +
                    (this.width / self.tramclock.totalTime) * tramSeconds;
                $('<div></div>')
                    .addClass('tramdot')
                    .css({ left: posLeft + 'px' })
                    .appendTo(self.$el);
            }
        }
    },

    moveTo(toSecond) {
        let self = this;
        let posLeft = self.getPosLeft(toSecond);

        if (this.prepend) {
            posLeft -= this.width;
        }

        self.$el.css('left', posLeft + 'px');
    },

    getPosLeft(toSecond) {
        let self = this;

        let posLeft =
            (-(self.$el.width() / this.count) / self.tramclock.totalTime) *
            toSecond;
        if (this.prepend) {
            posLeft -= this.width;
        }
        return posLeft;
    },

    checkPrepend(toSecond) {
        let self = this;
        let posLeft = self.getPosLeft(toSecond);

        // Need to prepend timeline?
        if (-$(window).width() / 2 < posLeft) {
            self.$el.width(self.$el.width() + this.width);
            this.count++;
            this.loadDots();
            this.prepend = true;
        }
    },

    checkAppend(toSecond) {
        let self = this;
        let posLeft = self.getPosLeft(toSecond);

        if (this.prepend) {
            posLeft -= this.width;
        }

        // need to append ?
        if (posLeft + self.$el.width() < $(window).width() * 2) {
            self.$el.width(self.$el.width() + self.width);
            this.count++;
            this.loadDots();
        }
    },
};

module.exports = Timeline;
