import $ from 'jquery';
import './vendor/bigvideo';
import tramFiles from './file-lists/video-files';

let TramVideo = function (tramclock) {
    this.tramclock = tramclock;
    this.ambientsound = this.tramclock.ambientsound;

    this.init();
};

TramVideo.prototype = {
    videoTimes: [],
    addedTime: 0,
    tramCounter: 0,

    lastTramBV: null,
    $lastTramWrap: null,

    currentTramBV: null,
    $currentTramWrap: null,

    nextTramBV: null,
    $nextTramWrap: null,

    currentTramIndex: 0,

    isRunning: false,
    isPlaying: false,

    lastVideoEndedTime: null,

    init() {
        let self = this;

        // load video times
        $.each(tramFiles, function (index, val) {
            self.videoTimes[index] = self.tramclock.helpers.timestrToSec(val);
        });

        self.initStartPlayers();

        //document.addEventListener('keydown', function (evt) {
        //    if (evt.keyCode === 81) { //Q
        //        let player = self.lastTramBV.getPlayer();
        //        console.log((Math.floor(player.duration()).toFixed(2)), player.duration(), (Math.floor(player.duration()*10)/10).toFixed(2));
        //        player.currentTime((Math.floor(player.duration()*10)/10).toFixed(2));
        //        //player.play();
        //        //player.pause();
        //
        //    }
        //    if (evt.keyCode === 67) { //c
        //        let player = self.lastTramBV.getPlayer();
        //        player.currentTime(player.duration()-2);
        //    }
        //    if (evt.keyCode === 66) { //b
        //        //self.ambientsound.restart();
        //
        //        self.makeTheTramMove();
        //    }
        //});
    },

    setCurrentTramIndex() {
        this.currentTramIndex = this.getCurrentTramIndex();
    },

    getTramTime(tramIndex) {
        let self = this;
        return self.addedTime + self.videoTimes[tramIndex] * 1000;
    },

    startTimer(seconds, unstoppable) {
        // fallback if in background
        let self = this;
        let timer = setInterval(function () {
            self.tick(self.tramclock.helpers.getCorrectedNow());
        }, seconds * 1000);

        if (!unstoppable) self.checkTimer = timer;
    },

    stopTimer() {
        let self = this;
        clearInterval(self.checkTimer);
    },

    tick(munichNow) {
        let self = this;

        let progress = self.getProgress(munichNow);

        if (
            !self.isRunning &&
            self.$currentTramWrap.hasClass('has-loaded') &&
            self.$lastTramWrap.hasClass('has-loaded')
        ) {
            self.isRunning = true;
            //self.$currentTramWrap.delay(500).hide().fadeIn('slow');
            //self.$lastTramWrap.delay(500).hide().fadeIn('slow');
            $('#loadscreen').delay(2000).fadeOut('slow');
        }

        if (self.isPlaying) {
            let paused = self.lastTramBV.getPlayer().paused();
            if (paused) {
                self.videoEnded();
            }
        } else {
            let opacity = Math.max(
                0,
                Math.min(Math.floor(progress * 100) / 100, 1),
            );
            self.$currentTramWrap.css('opacity', opacity.toFixed(2));
        }

        if (progress > 1) {
            self.makeTheTramMove();
        }
    },

    getProgress(munichNow) {
        let self = this;

        let currentTramTime = self.getTramTime(self.currentTramIndex);
        let lastTramIndex = self.getLastTramIndex();
        let subtractAdded =
            self.currentTramIndex === 0 || self.currentTramIndex === 1
                ? self.tramclock.totalTime * 1000
                : 0;
        let lastTramTime =
            self.lastVideoEndedTime !== null
                ? self.lastVideoEndedTime
                : self.getTramTime(lastTramIndex);

        lastTramTime -= subtractAdded;
        let tramGap = currentTramTime - lastTramTime;

        return (munichNow - lastTramTime) / tramGap;
    },

    makeTheTramMove() {
        let self = this;

        // remove old video dom element
        self.$lastTramWrap.remove();

        // stop audio
        this.ambientsound.stop();

        // play next video
        self.$currentTramWrap.css('opacity', 1);
        self.currentTramBV.getPlayer().play();
        self.isPlaying = true;

        // move references
        self.lastTramBV = self.currentTramBV;
        self.$lastTramWrap = self.$currentTramWrap;

        self.currentTramBV = self.nextTramBV;
        self.$currentTramWrap = self.$nextTramWrap;

        // load next tram
        self.loadNextTram();
    },

    loadNextTram() {
        let self = this;

        self.setCurrentTramIndex();

        let nextTramIndex = self.getNextTramIndex();

        let nextTram = this.initBVPlayer(nextTramIndex);
        self.nextTramBV = nextTram[0];
        self.$nextTramWrap = nextTram[1];
    },

    initStartPlayers() {
        let self = this;

        self.setCurrentTramIndex();

        let lastTram = this.initBVPlayer(self.getLastTramIndex(), true);
        self.lastTramBV = lastTram[0];
        self.$lastTramWrap = lastTram[1];
        self.$lastTramWrap.css('opacity', 1);

        let currentTram = this.initBVPlayer(self.currentTramIndex);
        self.currentTramBV = currentTram[0];
        self.$currentTramWrap = currentTram[1];

        self.loadNextTram();
    },

    initBVPlayer(tramFileIndex, jumpToLastFrame) {
        if (typeof jumpToLastFrame === 'undefined') {
            jumpToLastFrame = false;
        }

        let self = this;
        let wrapId = 'tcwrp' + self.tramCounter;

        let BV = new $.BigVideo({
            vidEl: '#tcvid' + self.tramCounter,
            wrapId: wrapId,
            volume: 1,
            container: $('.videocontent'),
            useFlashForFirefox: false,
        });

        BV.init();

        BV.show(
            'https://tramclockmunich.sos-ch-gva-2.exo.io/video/' +
                tramFiles[tramFileIndex] +
                '.mp4',
        );

        let $wrap = $('#' + wrapId);
        $wrap.css('z-index', self.tramCounter);

        let vol = self.tramclock.isMuted ? 0 : 1;
        let player = BV.getPlayer();

        // player.L.setAttribute('playsinline', true);
        let videoElements = document.getElementsByTagName('video');
        for (const videoElement of videoElements) {
            videoElement.setAttribute('playsinline', true);
        }

        player.volume(vol);
        player.pause();
        player.on('loadeddata', function () {
            //if($wrap.hasClass('has-loaded')) return;

            if (jumpToLastFrame && player.duration() > 0) {
                player.currentTime(
                    (Math.floor(player.duration() * 10) / 10).toFixed(2) - 1,
                );
                //player.currentTime(player.duration());

                self.lastVideoEndedTime =
                    self.getTramTime(tramFileIndex) + player.duration() * 1000;

                player.on('timeupdate', function () {
                    $wrap.addClass('has-loaded');
                });
            } else {
                player.currentTime(0.1);
                $wrap.addClass('has-loaded');
            }

            // player.on('loadeddata', function () {});
        });

        if (!jumpToLastFrame) {
            player.on('timeupdate', function () {
                if (
                    player.currentTime() >
                    Math.floor(player.duration()).toFixed(2)
                ) {
                    //console.log('pause - ended', player.currentTime(), player.duration(), Math.floor(player.duration()));
                    player.pause();
                    //player.currentTime(Math.floor(player.duration()).toFixed(2));
                    if (self.isPlaying) self.videoEnded();
                }
            });
        }

        player.on('ended', function () {
            //console.log('ended', player.currentTime(), player.duration(), Math.floor(player.duration()));
            player.pause();
            //player.currentTime(Math.floor(player.duration()).toFixed(2));
            //player.currentTime(player.currentTime());
            if (self.isPlaying) self.videoEnded();
        });

        self.tramCounter++;

        return [BV, $wrap];
    },

    videoEnded() {
        let self = this;
        self.lastVideoEndedTime = self.tramclock.helpers.getCorrectedNow();
        self.isPlaying = false;
        self.ambientsound.restart();
    },

    fixLastVideo() {
        let self = this;
        if (self.isPlaying) return;

        let player = self.lastTramBV.getPlayer();
        if (player.readyState === 4)
            player.currentTime(
                (Math.floor(player.duration() * 10) / 10).toFixed(2) - 1,
            );
    },

    setVolume(vol) {
        let self = this;

        self.lastTramBV.getPlayer().volume(vol);
        self.currentTramBV.getPlayer().volume(vol);
        self.nextTramBV.getPlayer().volume(vol);
    },

    getCurrentTramIndex() {
        let self = this;

        let munichNow = self.tramclock.helpers.getCorrectedNow();
        let nearest = [-1, self.tramclock.totalTime * 1000];

        for (let i = self.videoTimes.length - 1; i >= 0; i--) {
            let distance =
                self.addedTime + self.videoTimes[i] * 1000 - munichNow;
            if (distance > 0 && distance < nearest[1]) nearest = [i, distance];
        }

        if (nearest[0] < 0) {
            self.addADay();
            return self.getCurrentTramIndex();
        }

        return nearest[0];
    },

    getNextTramIndex() {
        return this.currentTramIndex >= tramFiles.length - 1
            ? 0
            : this.currentTramIndex + 1;
    },

    getLastTramIndex() {
        return this.currentTramIndex === 0
            ? tramFiles.length - 1
            : this.currentTramIndex - 1;
    },

    addADay() {
        let self = this;
        self.addedTime = self.addedTime + self.tramclock.totalTime * 1000;
    },
};

module.exports = TramVideo;
