import BufferLoader from './bufferloader';
import MessageFiles from './file-lists/audio-timed-files';

let SoundMessages = function (tramclock) {
    this.tramclock = tramclock;
    this.init();
};

SoundMessages.prototype = {
    next: null,
    addedTime: 0,
    source: null,
    gain: null,

    init() {
        let CrossFaderPlaylist = require('./crossfader');
        this.crossfader = new CrossFaderPlaylist(this);

        this.setNext();
    },

    startTimer(seconds, unstoppable) {
        // fallback if in background
        let self = this;
        let timer = setInterval(function () {
            let munichNow = self.tramclock.helpers.getCorrectedNow();
            self.tick(munichNow);
        }, seconds * 1000);

        if (!unstoppable) self.checkTimer = timer;
    },

    stopTimer() {
        let self = this;
        clearInterval(self.checkTimer);
    },

    tick(munichNow) {
        let self = this;

        if (!self.next) self.setNext();

        let timeOfNext = self.getTimeOfMessageFile(self.next);
        if (timeOfNext <= munichNow) self.playNext();
    },

    playNext() {
        let self = this;
        let context = self.tramclock.ambientsound.context;

        let nextSound = self.next;

        let bufferLoader = new BufferLoader(
            context,
            ['/media/audio/timed/' + MessageFiles[nextSound] + '.mp3'],

            function (buffer) {
                let source = context.createBufferSource();
                source.buffer = buffer[0];
                //source1.connect(context.destination);

                self.tramclock.ambientsound.stop();
                //playHelper(buffer[0]);

                // Create a gain node.
                let gainNode = context.createGain();
                // Connect the source to the gain node.
                source.connect(gainNode);
                // Connect the gain node to the destination.
                gainNode.connect(context.destination);
                self.gain = gainNode;

                gainNode.gain.value = self.tramclock.isMuted ? 0 : 0.6;

                if (!self.tramclock.isMuted) {
                    source.start(0);
                    //console.log('source started');
                    setTimeout(function () {
                        self.tramclock.ambientsound.restart();
                        //console.log('timeout after source started');
                    }, source.buffer.duration * 1000);
                }
            },
        );

        bufferLoader.load();

        self.setNext();
    },

    setNext() {
        let self = this;

        //time to next sound
        // let now = new Date().getTime();
        let latest = [-1, self.tramclock.totalTime * 1000];

        // find next
        for (let i = MessageFiles.length - 1; i >= 0; i--) {
            let distance =
                self.getTimeOfMessageFile(i) -
                self.tramclock.helpers.getCorrectedNow();
            if (distance > 0 && distance < latest[1]) {
                latest = [i, distance];
            }
        }

        if (latest[0] === -1) {
            //assume its on the next day
            this.addedTime += self.tramclock.totalTime * 1000;
            //console.log('playnext time added');
            this.setNext();
            return;
        }

        self.next = latest[0];
    },

    getTimeOfMessageFile: function (index) {
        let self = this;
        return (
            this.addedTime +
            self.tramclock.helpers.timestrToSec(MessageFiles[index]) * 1000
        );
    },
};

module.exports = SoundMessages;
