import $ from 'jquery';
import helpers from './helpers';
import { ticktack } from './vendor/ticktack';
import canAutoplay from 'can-autoplay';
import webAudioTouchUnlock from 'web-audio-touch-unlock';

let TramClock = function () {
    this.helpers = new helpers();
    this.helpers.init();

    this.AudioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    webAudioTouchUnlock(this.AudioContext);

    this.init();
    this.initTimeline();
    this.initListeners();

    canAutoplay.audio().then(({ result }) => {
        if (result === true) {
            this.start();
        }
    });
};

TramClock.prototype = {
    isMuted: false,
    totalTime: 24 * 60 * 60,

    tramvideo: null,
    ambientsound: null,
    soundmessages: null,
    timeline: null,

    initListeners() {
        $('#loadscreen').on('click', (e) => {
            e.preventDefault();

            this.AudioContext.resume().then(() => {
                this.start();
            });
        });

        $('#logo').on('click', function (e) {
            e.preventDefault();
            $('#about').fadeToggle(500);
        });
    },

    init() {
        let self = this;

        // setInterval(() => {
        //     console.log(self.ambientsound.isPlaying());
        // }, 2000);

        // let Timeline = require('./timeline');
        // self.timeline = new Timeline(self);
    },

    start() {
        $('#startButton').remove();
        $('#loadimage').show();

        let self = this;

        let AmbientSound = require('./ambientsound');
        self.ambientsound = new AmbientSound(self);
        self.ambientsound.init();
        self.ambientsound.play();

        let SoundMessages = require('./soundmessages');
        self.soundmessages = new SoundMessages(self);

        let TramVideo = require('./tramvideo');
        self.tramvideo = new TramVideo(self);

        if (window.requestAnimationFrame) {
            self.startClock();

            window.addEventListener(
                'blur',
                function () {
                    self.startTimers(1);
                },
                false,
            );

            window.addEventListener(
                'focus',
                function () {
                    self.stopTimers();
                    self.tramvideo.fixLastVideo();
                },
                false,
            );

            self.startTimers(20, true);
        }

        $('#mute').on('click', function (event) {
            event.preventDefault();
            self.toggleMute();
        });

        document.addEventListener('keydown', function (evt) {
            if (evt.code === 'KeyM' || evt.code === 'Space') {
                self.toggleMute();
            }
        });
    },

    initTimeline() {
        let self = this;

        let Timeline = require('./timeline');
        self.timeline = new Timeline(self);

        ticktack.on('second', function () {
            let munichNow = self.helpers.getCorrectedNow();
            self.timeline.moveTo(munichNow / 1000);
        });
    },

    toggleMute() {
        let self = this;

        $('#mute').toggleClass('is-muted');

        //mute
        self.isMuted = !self.isMuted;
        let vol = self.isMuted ? 0 : 1;
        if (self.isMuted) {
            self.ambientsound.stop();
        } else {
            if (!self.tramvideo.isPlaying) self.ambientsound.restart();
        }

        self.tramvideo.setVolume(vol);
        if (self.soundmessages.gain) self.soundmessages.gain.gain.value = vol;
    },

    startTimers(seconds) {
        let self = this;
        self.tramvideo.startTimer(seconds, false);
        self.soundmessages.startTimer(seconds, false);
        self.timeline.startTimer(seconds, false);
    },

    stopTimers() {
        let self = this;
        self.tramvideo.stopTimer();
        self.soundmessages.stopTimer();
        self.timeline.stopTimer();
    },

    startClock() {
        let self = this;

        ticktack.on('second', function (/*digits*/) {
            let munichNow = self.helpers.getCorrectedNow();

            self.tramvideo.tick(munichNow);
            self.soundmessages.tick(munichNow);
            // self.timeline.moveTo(munichNow / 1000);
        });
    },
};

new TramClock();
